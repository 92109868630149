@use "sass:math";

$description-image-size: 150px;

.CityDescription {
  background-color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: $spacing--large;
  @include lt(small) {
    padding: $spacing--small;
  }
  padding-bottom: $spacing--small;
  margin: 0;
  > li {
    border-top: #f0f0f0 solid 1px;
    padding: 0 $spacing--large;
    padding-top: $spacing--small;
    @include lt(small) {
      padding: 0;
    }
  }
  > :first-child {
    border-top: 0;
    padding-top: 0;
  }
}
.CityDescription--hidden {
  display: none;
}

.CityDescriptionCard-richTextField {
  p {
    margin-bottom: $spacing--small;
  }

  a,
  a:visited {
    color: $palette--purple;
    text-decoration: none;
  }

  a:hover {
    color: $palette--dark-purple;
  }

  ul,
  ol {
    padding-left: $spacing--small;
    margin-bottom: $spacing--small;

    li {
      margin-bottom: math.div($spacing--small, 3);
    }
  }

  ul li {
    list-style: disc;
  }
}

.CityDescriptionCard-richTextFieldSmall {
  font-size: $font-size--g;

  p {
    margin-bottom: math.div($spacing--small, 3);
  }

  a,
  a:visited {
    color: $palette--purple;
    text-decoration: none;
  }

  a:hover {
    color: $palette--dark-purple;
  }

  ul,
  ol {
    padding-left: $spacing--small;
    margin-bottom: math.div($spacing--medium, 3);

    li {
      margin-bottom: math.div($spacing--small, 3);
      line-height: $font-size--f;
    }
  }

  ul li {
    list-style: disc;
  }
}

.CityDescriptionCard {
  &.CityDescriptionCard-additionalContent--hidden {
    display: none;
  }
  margin-bottom: $spacing--medium;
  display: grid;
  grid-template-rows: auto auto $description-image-size auto;
  grid-template-areas:
    "mainTitle"
    "mainText"
    "mainImage"
    "additionalContent";
  justify-items: start;
  grid-row-gap: $spacing--medium;
  grid-column-gap: $spacing--large;
  // min-height: 350px;
  @include lt(small) {
    padding: $spacing--small;
    grid-template-columns: auto;
    grid-template-rows: auto auto $description-image-size;
    grid-row-gap: $spacing--small;
    grid-template-areas:
      "mainTitle"
      "mainText"
      "mainImage"
      "additionalContent";
  }
}

.CityDescriptionCard-mainTitle {
  grid-area: mainTitle;
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 29px;
    @include lt(small) {
      margin-bottom: 10px;
    }
  }
}
.CityDescriptionCard-mainText {
  text-align: justify;
  grid-area: mainText;
  font-size: $font-size--f;
  line-height: 18px;
  color: $color-text;

  p:last-child {
    margin-bottom: 0;
  }
}
.CityDescriptionCard-mainImage {
  position: relative;
  height: 100%;
  width: 100%;
  grid-area: mainImage;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  figcaption {
    margin-top: -$spacing--micro * 2;
    font-size: $font-size--h;
    color: $color-share-gray;
  }
}

.CityDescriptionCard-additionalContent {
  &.CityDescriptionCard-additionalContent--hidden {
    display: none;
  }
  grid-area: additionalContent;
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  .CityDescriptionCard-additionalContentRow {
    margin-bottom: 50px;
  }
  :last-child {
    margin-bottom: 0;
  }
  .CityDescriptionCard-additionalContentRowHeader {
    padding-bottom: $spacing--medium;
    p {
      line-height: 15px;
      font-size: 12px;
      color: $color-text;
    }
  }

  .CityDescriptionCard-additionalContentColumns {
    display: grid;
    grid-row-gap: $spacing--large;
    grid-column-gap: $spacing--medium;
    grid-template-columns: repeat(
      auto-fill,
      calc(50% - (#{$spacing--medium} / 2))
    );

    .CityDescriptionCard-additionalContentColumnTitle {
      margin-bottom: 15px;
      font-weight: 500;
      line-height: 20px;
      font-size: 16px;
      color: $color-text;
    }
    .CityDescriptionCard-additionalContentColumnText {
      line-height: 15px;
      font-size: 12px;
      text-align: justify;
      color: $color-text-gray;
    }
    @include lt(small) {
      grid-column-gap: 0;
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  .CityDescriptionCard-additionalContentImage {
    height: $description-image-size;
    margin-top: $spacing--medium;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    figcaption {
      margin-top: -$spacing--micro * 2;
      font-size: $font-size--h;
      color: $color-share-gray;
    }
  }
}

.CityDescriptionCard-readMoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 160px;
  height: 40px;
  border-radius: $border-radius--large * 2;
  background: #ffffff;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  span {
    margin: 0 5px;
  }
  i {
    border: solid #a03fc5;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: all 0.1s linear;
  }
  .up {
    transform: rotate(-135deg);
    margin-bottom: -2px;
  }
  .down {
    transform: rotate(45deg);
    margin-bottom: 2px;
  }
}
