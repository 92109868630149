@import "../../../styles/variables.scss";

.RefugeeDistributionModal-registrationLink {
  color: $palette--wunder-brand-purple;
  text-decoration: underline;
}

.Step-Title {
  max-width: 424px;
}
