@import "../../styles/variables.scss";
@import "../../styles/media";

.ProgressBar {
  background-color: $palette--tint-3-grey;
  width: 100%;
}

.ProgressBar-wrapper {
  width: 50%;
  margin: auto;
  background: linear-gradient(to right, $palette--tint-3-grey 5%, #c1c9cf 100%);

  @include lt(small) {
    width: 80%;
    margin-left: 0;
  }
}

.ProgressBar-indicator {
  display: block;
  height: 5px;
  background: linear-gradient(270deg, #542ebc 75%, rgba(84, 46, 188, 0) 100%);
}
