.DatesInfoBanner {
  padding: 0 $spacing--small;

  @include gt(1146px) {
    padding: 0 50px;
  }
}

.DatesInfoBanner-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing--medium 0;

  @include gt(medium) {
    flex-direction: row;
  }
}

.DatesInfoBanner-outerText {
  text-align: center;

  @include gt(medium) {
    margin-right: $spacing--small;
    text-align: left;
  }
}

.DatesInfoBanner-title {
  @include typo--heading-4;
}

.DatesInfoBanner-text {
  @include typo--body-2;
}

.DatesInfoBanner-cta {
  overflow: visible;
  white-space: nowrap;
  margin-top: $spacing--small;

  @include gt(medium) {
    margin-top: 0;
    margin-left: auto;
  }
}
