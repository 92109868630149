@import "../../../../../../styles/variables.scss";

.PriceFilter {
  flex-direction: column;
  max-width: 280px;
  padding: $spacing--base * 4;
  padding-bottom: 0;

  label[for="maxPriceInput"] {
    text-align: right;
  }

  @include gt(#{$listingspage-tablet-breakpoint + 1}) {
    align-items: center;
    overflow: hidden;
    min-width: 200px;

    .Input-affixWrapper {
      height: $spacing--base * 8;
      border-radius: $border-radius-base;

      .Input-prefix {
        padding-left: 8px;
        font-size: $font-size--e;
        font-weight: $font-weight--medium;
      }

      .Input {
        padding: 0 $spacing--base;
        font-size: $font-size--e;
        font-weight: $font-weight--medium;
        border-radius: $border-radius-base;
      }
    }

    .Input-label {
      width: 100%;
      color: $color-light;
    }
  }
}

.PriceFilter-caption {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: $palette--grey;
}

.PriceFilter-priceFieldsOuter {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.PriceFilterCompact-inputsOuter {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.PriceFilter-inputsSpacer {
  line-height: $spacing--base * 8;
  margin: 0 $spacing--base * 2;
  color: $palette--silver;
  max-height: $spacing--base * 8;
  align-self: flex-end;
}

.PriceFilter-resetValuesButton {
  color: $color-2019--font-primary;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  font-family: $primary-font-stack;
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.PriceFilter-depositCheckbox {
  padding-top: $spacing--base * 6;
}

.PriceFilter-submitButton {
  min-width: 146px;
}

.PriceFilter-buttons {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.PriceFilterLoading {
  display: flex;
  flex-direction: column;
  width: 240px;
}

@keyframes preloadAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.PriceFilterLoading-item {
  position: relative;
  background: linear-gradient(90deg, #f7f9fa 0%, #edf1f4 100%);
  border-radius: 2px;

  &:after {
    position: absolute;
    content: "";
    animation: preloadAnimation 1.5s infinite;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.08);
    opacity: 0;
  }
}

.PriceFilterLoading-histogram {
  height: 64px;
}

.PriceFilterLoading-labels {
  margin-top: $spacing--base * 4;
  display: flex;
  justify-content: space-between;
}

.PriceFilterLoading-labelItem {
  height: 13px;
  width: $spacing--base * 8;
}

.PriceFilterLoading-inputs {
  margin-top: $spacing--base * 4;
  display: flex;
  justify-content: space-between;
}

.PriceFilterLoading-inputItem {
  height: 36px;
  width: $spacing--base * 22;
}

.PriceFilterLoading-resetLink {
  height: $spacing--base * 4;
  width: 73px;
}

.PriceFilterLoading-footer {
  display: flex;
  align-items: center;
  margin-top: 27px;
}

.PriceFilterLoading-applyButton {
  margin-left: auto;
  width: $spacing--jumbo;
  height: $button-2019-default;
}
