@import "../../../styles/variables.scss";
@import "../../../styles/media";

.Step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &-IconContainer {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }

  &-Title {
    @include typo--heading-2;
    margin-bottom: 15px;
    max-width: 335px;
    text-align: center;
  }

  &-Body {
    @include typo--body-2;
    font-weight: 400;
    margin-top: 15px;
  }

  &-ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 30px;
    flex: 1 1 50%;

    @include lt(small) {
      flex-direction: column-reverse;
      flex-basis: 100%;

      & .Button {
        width: 100%;
        white-space: break-spaces;
        height: auto;
        line-height: unset;
        padding: 10px 30px;
      }
    }
    // TODO Design System update - remove once colors are updated
    & .Button--brandPurple {
      background: $palette--wunder-brand-purple;
      &:disabled {
        background: #cac0e7;
      }
      &:hover:disabled {
        background: #cac0e7;
      }
    }
  }
}
