.AccommodationTypeFilter {
  padding: $spacing--base * 3 $spacing--base * 4;

  .StyledCheckbox-iconContainer {
    min-width: $spacing--base * 5;
    min-height: $spacing--base * 5;
    border: 1px solid $palette--silver;
  }

  .StyledCheckbox-iconContainer-icon {
    transform: scale(0.72);
  }
}

.AccommodationTypeFilter-checkboxOuter {
  margin-top: $spacing--base * 3;
}

.AccommodationTypeFilter-heading {
  @include typo--body-2;
  color: $color-2019--font-primary;
}

.ListingFilterModal {
  .AccommodationTypeFilter {
    padding: 0;
  }

  .AccommodationTypeFilter-heading {
    display: none;
  }

  .AccommodationTypeFilter-checkboxOuter {
    margin-top: 0;
  }
}
