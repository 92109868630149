.AmenitiesFilter {
  width: calc(#{$spacing--x-large * 3} - #{$spacing--base * 4});
  padding: 0 $spacing--base * 2;
  max-height: calc(100vh - #{$spacing--very-large * 2});
  overflow-y: auto;

  .StyledCheckbox-iconContainer {
    min-width: $spacing--base * 5;
    min-height: $spacing--base * 5;
    border: 1px solid $palette--silver;
  }

  .StyledCheckbox-iconContainer-icon {
    transform: scale(0.72);
  }

  .StyledCheckbox-text {
    color: $color-2019--font-secondary;
    font-size: $font-size--f;
    font-weight: $font-weight--medium;
  }

  .StyledCheckbox-text--right {
    padding-left: $spacing--small - 2px;
  }
}

.AmenitiesFilter-rowList > li:not(:last-child) {
  display: flex;
  margin-bottom: calc(#{$spacing--small} - 2px);
}

.AmenitiesFilter-heading {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  color: $palette--grey;
  margin: 15px 0 24px 0;
}

.AmenitiesFilter-buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}

.AmenitiesFilter-clearBtn {
  background-color: transparent;
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  color: $color-2019--font-primary;
  margin-left: $spacing--base * 2;
  border: none;
  font-family: $primary-font-stack;

  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.AmenitiesFilter-submitBtn {
  width: 146px;
}

.AmenitiesFilter .StyledCheckbox {
  width: 100%;
  cursor: pointer;
}
