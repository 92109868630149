@import "../../styles/variables.scss";
@import "../../styles/media.scss";

$handle-size: $spacing--base * 4;
$handle-size-mobile: 26px;
$slider-thickness: 2px;

.Slider {
  min-width: 100%;
}

.Slider--disabled {
  .DefaultProgressBar_progressBar {
    background-color: $palette--tint-4-grey;
  }
}

/*
 * These classes are exclusive to Airbnb Rheostat
 * DO NOT REMOVE THEM
*/

.DefaultProgressBar__vertical {
  width: $slider-thickness;
  height: 100%;
}

.DefaultProgressBar_progressBar {
  background-color: $palette--wunder-purple;
  position: absolute;
}

.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: $slider-thickness;
}

.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: $slider-thickness;
}

.DefaultProgressBar_background__horizontal {
  height: $slider-thickness;
  top: 0px;
}

.DefaultHandle_handle {
  width: $handle-size-mobile;
  height: $handle-size-mobile;

  border: 1.5px solid $palette--silver;
  background-color: #fcfcfc;
  border-radius: 100%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #dbdbdb;
  cursor: pointer;

  @include gt(large) {
    width: $handle-size;
    height: $handle-size;
  }
}

.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px;
}

.DefaultHandle_handle:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8;
}

.DefaultHandle_handle:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8;
}

.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -12px;

  @include gt(large) {
    margin-left: -10px;
    top: -8px;
  }
}

.DefaultHandle_handle__vertical {
  margin-top: -8px;
  left: -10px;
}

.DefaultHandle_handle__vertical:before {
  top: 10px;
}

.DefaultHandle_handle__vertical:after {
  top: 13px;
  left: 8px;
  height: 1px;
  width: 10px;
}

.DefaultHandle_handle__disabled {
  border-color: #dbdbdb;
}

.DefaultBackground {
  background-color: $palette--silver;
  height: $slider-thickness;
  width: 100%;
  position: relative;
}

.DefaultBackground_background__horizontal {
  height: $slider-thickness;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.DefaultBackground_background__vertical {
  width: $slider-thickness;
  top: 0px;
  height: 100%;
}

.rheostat {
  position: relative;
  overflow: visible;
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}

.rheostat__vertical {
  height: 100%;
}

.handleContainer {
  height: 2px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
}

.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}

.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}

.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}
