@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/icons.scss";
@import "../../styles/media.scss";
@import "./variables.scss";

.SearchDrawerTrigger {
  cursor: pointer;
  outline: inherit;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $palette--pure-white;
  border: 1px solid $palette--tint-3-grey;
  box-shadow: $box-shadow-inputSearch;
  border-radius: $border-radius-inputSearch;
  padding-left: $spacing--base * 3;
  padding-right: $spacing--micro;
  padding-top: $spacing--micro;
  padding-bottom: $spacing--micro;

  @include gt(small) {
    padding-left: $spacing--base * 4;
  }
}

.SearchDrawerTrigger-Text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: $spacing--base;
  padding-right: $spacing--base * 4;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  width: calc(100vw - 180px); // screen width - 180px (icon + menu)
  @media (hover: hover) {
    width: calc(
      100vw - 200px
    ); // screen width - 180px (icon + menu + scrollbar)
  }
  @include typo--heading-4;

  &--placeholder {
    color: $palette--silver;
  }
}

.SearchDrawerTrigger-SearchIcon {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
  border-radius: $border-radius--large * 2;
  min-width: $iconSize;
  width: $iconSize;
  height: $iconSize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchDrawerTrigger--isTransparent {
  background: $background-input-transparent;
  border: $border-transparent-element;

  .SearchDrawerTrigger-Text {
    color: $palette--pure-white;
  }
  .SearchDrawerTrigger-SearchIcon {
    border: $border-transparent-element;
    background: rgba(0, 0, 0, 0.25);
  }
}
