.MoreFilters {
  padding-bottom: 50px;
}

.MoreFilters-popperInner {
  padding: 0;
  width: 308px;
  max-height: 80vh;
  overflow: scroll;
}

.MoreFilters-section {
  border-bottom: 1px solid $palette--tint-3-grey;
}

.MoreFilters-clearAllButton {
  background-color: transparent;
  color: $palette--black;
  border: none;
  cursor: pointer;
  font-size: $font-size--f;
  line-height: 36px;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  margin-left: $spacing--base * 2;
}

.MoreFilters-footer {
  display: flex;
  padding: $spacing--base * 2;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
}

button.MoreFilters-apply {
  margin: 0 0 0 auto;
  width: 145px;
}
