@import "../../../styles/variables.scss";

.InputAutocomplete {
  position: relative;
}

.InputAutocomplete-suggestionsBox {
  box-shadow: $box-shadow-slight;
  border-radius: $border-radius--medium;
  border: 1px solid $palette--light-gray-9;
  background: white;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 5px;
  z-index: 5;
}

.InputAutocomplete-suggestionsList {
  padding: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.InputAutocomplete-suggestionItem {
  background: white;
  border: none;
  font-family: inherit;
  font-size: $font-size--f;
  padding: 15px;
  display: block;
  width: 100%;
  text-align: left;
  border-radius: 5px;
}

.InputAutocomplete-suggestionItem--focused,
.InputAutocomplete-suggestionItem:hover {
  background: $palette--tint-1-grey;
}

.InputAutocomplete-loadingSpinnerContainer {
  height: 100%;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: inline-flex;
  align-items: center;
}
