@use "sass:math";

$spacing--x-micro: math.div($spacing--small, 3);

.ListingDetailsCard {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.ListingDetailsCard-linkArea {
  color: #000;
}

.ListingDetailsCard-images {
  padding: $spacing--x-micro;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  img {
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ListingDetailsCard-imageItem {
  height: 150px;
  margin-right: $spacing--x-micro;
  border-radius: 3px;
}

.ListingDetailsCard-info {
  padding: 0 $spacing--small;
  display: flex;
  flex-direction: column;
}

.ListingDetailsCard-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $font-size--f;
}

.ListingDetailsCard-price {
  font-size: $font-size--f;
}

.ListingDetailsCard .StudentExclusivePill--short {
  top: ($spacing--base * 3) - 3px;
  left: $spacing--base * 3;
}

.ListingDetailsCard-shortlistButton {
  position: absolute;
  right: 20px;
  bottom: 15px;
}

.ListingDetailsCard .ShortlistButton.noLabel {
  width: $button-2019-default;
  height: $button-2019-default;
}
