.ApartmentSizeFilter {
  font-family: $primary-font-stack;
  color: $color-2019--font-primary;
  padding: 12px 20px 18px 20px;
}

.ApartmentSizeFilter-title {
  font-size: $font-size--f;
  line-height: 1.5;
  font-weight: $font-weight--medium;
}

.ApartmentSizeFilter-subtitle {
  color: $palette--grey;
  font-size: $font-size--f;
  line-height: 1.5;
  margin-bottom: $spacing--small - 2px;
  font-weight: $font-weight--medium;
}

.ApartmentSizeFilter-caption {
  color: $palette--grey;
  font-size: $font-size--g;
  font-weight: $font-weight--medium;
  line-height: 1.5;
  padding-top: $spacing--base - 1px;
}

.ApartmentSizeFilter-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ApartmentSizeFilter-sizeBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $spacing--large + $spacing--micro;
}
