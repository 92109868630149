@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/icons.scss";
@import "../../styles/media.scss";
@import "./variables.scss";

.GeocodingInputSearch {
  width: $geocodingInputSearch-width;
  transition: width 0.3s;

  @include gt(small) {
    width: $geocodingInputSearch-width--small;
  }

  &--isFocused {
    width: 100%;
  }

  .InputText {
    border-radius: $border-radius-inputSearch;
    background: $palette--pure-white;
    border: 1px solid $palette--tint-4-grey;
    box-shadow: $box-shadow-inputSearch;
    padding: $spacing--micro $spacing--micro $spacing--micro
      ($spacing--base * 3);
    flex-direction: row;

    @include gt(xsmall) {
      padding-left: $spacing--base * 4;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .InputAutocomplete-InputText-Icon {
    background: $palette--tint-2-grey;
    border: 1px solid $palette--tint-3-grey;
    border-radius: $border-radius--large * 2;
    min-width: $iconSize;
    width: $iconSize;
    height: $iconSize;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .InputText-input {
    padding: 0 ($spacing--base * 4) 0 $spacing--base;
    border: none;
    background: none;
    min-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include typo--heading-4;

    &::placeholder {
      color: $palette--silver;
    }
  }
  .InputAutocomplete-suggestionItem {
    padding: 10px;
    cursor: pointer;
  }
}

.GeocodingInputSearch--light {
  width: 100%;
  .InputText {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
}

.GeocodingInputSearch--isTransparent {
  .InputText {
    background: $background-input-transparent;
    border: $border-transparent-element;
  }

  .InputText-input {
    color: $palette--pure-white;
  }

  .InputAutocomplete-InputText-Icon {
    border: $border-transparent-element;
    background: rgba(0, 0, 0, 0.25);
  }
}
