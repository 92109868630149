@use "sass:math";

@import "../../styles/variables.scss";
@import "../../styles/media.scss";
$pagination-ultra-small-breakpoint: 370px;

.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pagination-arrowLeft {
  transform: scale(0.8);
}

.Pagination-arrowRight {
  transform: scale(1.1);
  margin-left: auto;
  margin-right: math.div($spacing--large, 3);
}

.Pagination-previous {
  margin-right: auto;
  margin-left: $spacing--small;

  @include gt(small) {
    margin-right: math.div($spacing--large, 3);
  }
}

.Pagination-next {
  margin-left: auto;
  margin-right: $spacing--small;

  @include gt(small) {
    margin-left: math.div($spacing--large, 3);
  }
}

.PaginationItem {
  border-radius: $border-radius--jumbo;
  display: flex;
  color: $color-2019--font-primary;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: $font-size--f;
  cursor: pointer;
  width: $button-2019--large;
  height: $button-2019--large;
  margin: 0 $spacing--micro;

  @include lt($pagination-ultra-small-breakpoint) {
    margin: 0 2px;
  }

  &:hover {
    background: $color-bg-header-link-hover;
  }

  &.disabled {
    color: $palette--silver;
    cursor: not-allowed;

    &:hover {
      background: white;
    }
  }
}

.PaginationItem--active {
  color: $color-text-light;
  background: $palette--wunder-brand-purple;
  border: none;

  &:hover {
    background: $palette--wunder-brand-purple;
  }
}

.EllipsisItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: $button-2019--large;
  margin: 0 $spacing--micro;

  @include lt($pagination-ultra-small-breakpoint) {
    margin: 0 2px;
  }
}

.EllipsisItem-icon {
  margin-top: $spacing--micro;
}
