@import "./FilterModal/FilterModal.scss";
@import "./PriceFilter/PriceFilter.scss";
@import "./ApartmentSizeFilter.scss";
@import "./MoreFilters.scss";
@import "./HouseRulesFilter.scss";
@import "./AccommodationTypeFilter.scss";
@import "./AmenitiesFilter.scss";
@import "./BedsFilter.scss";
@import "./RoomFilter.scss";

.ListingFilter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 0;
  padding: 0 ($spacing--base * 3);
  align-items: center;

  @include lt($listingspage-tablet-breakpoint) {
    padding: $spacing--small ($spacing--base * 6);
  }

  @include lt(large) {
    padding: ($spacing--base * 3);
  }

  &-desktopContainer {
    display: flex;
    align-items: center;
  }

  &-label {
    color: $color-text;
  }

  &-value {
    display: block;
    color: $color-light;
  }

  &-rowList {
    list-style: none;
    padding: 0;
    white-space: nowrap;
  }

  &-tenants {
    width: 280px;
    padding-top: $spacing--base * 3;
    padding-bottom: $spacing--base * 2;
  }

  &-field-value {
    min-width: 80px;
    display: inline-block;
    font-size: $font-size--e;
    font-weight: $font-weight--bold;
    text-align: center;
  }

  &-field-calendar {
    > span {
      display: block;
      text-align: center;
      color: $color-light;
    }

    & + & {
      margin-left: $spacing--medium;
      border-left: 1px solid $color-bg;
      padding-left: $spacing--medium;
    }
  }

  .BubbleCount {
    margin-left: $spacing--base * 2;
    margin-right: -$spacing--base * 2;
  }

  @include lt($listingspage-tablet-breakpoint) {
    padding: $spacing--small;
  }
}

.ListingFilterButton,
.ListingFilterButton.TextButton,
.ListingFilterButton.Button--growWithText.Button--large {
  display: flex;
  align-items: center;
  margin-right: $spacing--base * 2;
  box-shadow: none;
  padding: 0 $spacing--base * 4;
}

.ListingsFilter-DateRangePicker {
  margin-right: 10px;
}

.ListingFilter-popperInner {
  padding: $spacing--base * 2;

  .PriceFilter {
    padding: $spacing--base * 4 $spacing--base * 4 0 $spacing--base * 4;
  }

  .ListingFilter-dates {
    display: flex;
  }

  .ListingFilter-dates {
    padding: $spacing--base $spacing--base * 4 $spacing--base * 2 $spacing--base *
      4;
  }

  .PriceFilter-submitButton {
    margin-right: -$spacing--base * 3;
    margin-bottom: $spacing--base;
  }

  .ListingFilter-tenants .NumberCounter {
    padding: 0 $spacing--base * 2;
  }

  .ListingFilter-tenantsSubmit {
    margin-top: $spacing--base * 5;
    margin-bottom: -$spacing--base * 2;
  }
}

.NumberCounter-btn {
  min-width: $button-2019--small;
  min-height: $button-2019--small;
}

.MobileDateFilter--hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
