@import "../../styles/media.scss";

.GeocodingSearch {
  .GeocodingInputSearchMobile {
    display: block;

    @include gt(small) {
      display: none;
    }
  }

  .GeocodingInputSearch {
    display: none;

    @include gt(small) {
      display: block;
    }
  }
}
