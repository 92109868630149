@import "../../styles/variables.scss";

.BubbleCount {
  font-size: $font-size--h;
  font-weight: $font-weight--bold;
  line-height: $line-height--micro;
  width: 22px;
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  color: $color-brand;
  background-color: $palette--tint-1-grey;
}
