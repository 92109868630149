@import "../../../../../styles/media.scss";
@import "../../../../../styles/variables.scss";

$height-filter-bar: 90px;
$height-filter-bar--mobile: 66px;

$height-banner: 33vw;

.AppHeader--withBanner {
  --bgColorOpacity: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease-out;
  background: rgba($color: #ffffff, $alpha: var(--bgColorOpacity));

  @include lt($listingspage-tablet-breakpoint) {
    &.mobile--hidden {
      // Animate top instead of translating because this element
      // already has a translated child that is outside of the viewport.
      // Adding a transform to this element would cause transformed children
      // to overflow content.
      top: -100%;
    }
  }
  &--static {
    position: relative;
  }
}
