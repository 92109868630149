$modal-footer-height: 70px;
$modal-header-height: 60px;

.ListingFilterModal {
  animation: appear 200ms;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 120;
  -webkit-overflow-scrolling: touch;

  label[for="maxPriceInput"] {
    text-align: right;
  }

  .ApartmentSizeFilter {
    padding: $spacing--base * 4;
  }

  .ApartmentSizeFilter-title {
    font-size: $font-size--e;
  }

  .ApartmentSizeFilter-subtitle {
    margin-bottom: $spacing--small + 1px;
    font-weight: $font-weight--medium;
  }

  @include gt(xsmall) {
    .ApartmentSizeFilter-btn {
      font-size: $font-size--d - 1px;
      height: $spacing--medium + 12px;
      width: $spacing--x-large + 5px;
    }
  }

  .ApartmentSizeFilter-caption {
    font-size: $font-size--f;
  }

  .ListingFilter-tenants {
    width: 100%;
    padding: $spacing--medium $spacing--base * 4;
    border-bottom: 1px solid $color-superlight;
  }

  .ListingFilter-tenants .NumberCounter-label {
    font-size: $font-size--e;
    font-weight: $font-weight--bold;
  }

  .Input-affixWrapper {
    height: $spacing--base * 8;
    border-radius: $border-radius-base;

    .Input-prefix {
      padding-left: 8px;
      font-size: $font-size--e;
      font-weight: $font-weight--medium;
    }

    .Input {
      padding: 0 $spacing--base;
      font-size: $font-size--e;
      font-weight: $font-weight--medium;
      border-radius: $border-radius-base;
    }
  }

  .Slider {
    padding: 0 $spacing--base * 2;
  }
}

// Custom modal close button
.ListingFilterModal-closeModalButton {
  position: absolute;
  top: 20px;
  right: 20px;

  background-color: $palette--pure-white;
  border: 1px solid $color-superlight;
  border-radius: 50%;
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  cursor: pointer;

  z-index: 5;
}

.ListingFilterModal-form {
  text-align: left;
  height: 100%;
  background: $palette--pure-white;
  padding: 0;

  @media only screen and (min-width: 481px) and (max-width: $listingspage-tablet-breakpoint) {
    padding: $spacing--medium $spacing--medium $spacing--very-large;
  }
}

.ListingFilterModal-scrollContainer {
  position: absolute;
  -webkit-overflow-scrolling: touch;
  top: $modal-header-height;
  right: 0;
  bottom: $modal-footer-height;
  left: 0;
  display: block;
  overflow: auto;
}

// Section
.ListingFilterModal-section {
  width: 100%;
  padding: $spacing--medium 20px;
  border-bottom: 1px solid $color-superlight;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.ListingFilterModal-section-title {
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  line-height: $line-height--small;
}

.ListingFilterModal-section-content {
  padding-top: 25px;
}

.ListingFilterModal-section .StyledCheckbox {
  margin-bottom: 20px;
}

.ListingFilterModal-section .PriceFilter-depositCheckbox {
  margin-bottom: 0px;
}

.ListingFilterModal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $font-size--e $font-size--d;

  background: $palette--pure-white;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.ListingFilterModal-clearFiltersButton {
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  color: $palette--dark-gray-4;
  background: none;
  border: 0;

  @media only screen and (max-width: 320px) {
    font-size: $font-size--g;
  }
}

// Amenities section
.ListingFilterModal-section-amenities .ListingFilterModal-section-content {
  padding-top: 5px;

  .amenitiesInfo {
    margin-bottom: $spacing--medium;
  }

  .amenitiesInfo-text {
    font-family: $primary-font-stack;
    font-style: normal;
    font-weight: $font-weight--medium;
    font-size: $font-size--f;
    line-height: $line-height--micro;
    color: $palette--grey;
  }

  .StyledCheckbox {
    width: 50%;
    margin-bottom: $spacing--small;
  }

  .StyledCheckbox-iconContainer {
    width: $spacing--medium;
    height: $spacing--medium;
    border: 1px solid $palette--silver;
  }

  .StyledCheckbox-iconContainer-icon {
    transform: scale(0.916);
  }

  .StyledCheckbox-input:focus + .StyledCheckbox-iconContainer {
    border: 1px solid $palette--silver;
  }

  .StyledCheckbox:last-of-type,
  .StyledCheckbox:nth-last-of-type(2) {
    margin-bottom: 0;
  }
}

.ListingFilterModal-section-houseRules
  .HouseRulesFilter-checkboxOuter:last-of-type {
  .StyledCheckbox {
    margin-bottom: 0;
  }
}
