@import "../../styles/variables.scss";

.NumberCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-2019--font-primary;

  &-label {
    font-size: $font-size--f;
    font-weight: $font-weight--medium;
    width: $spacing--very-large * 2;
  }

  &-icon {
    width: $spacing--small * 2;
    margin-right: $spacing--small;
  }

  &-value {
    width: $spacing--large;
    min-width: $spacing--large;
    font-size: $font-size--e;
    font-weight: $font-weight--bold;
    text-align: center;
  }

  &-btnContainer {
    display: inline-flex;
    width: $spacing--jumbo - $spacing--base;
  }

  &-btn {
    position: relative;
    width: $button-2019--small;
    height: $button-2019--small;
    line-height: $button-2019--small;
    border: 1px solid $palette--tint-3-grey;
    border-radius: 50%;
    background: $palette--tint-2-grey;
    cursor: pointer;
    outline: none;
    padding: 1px;
  }

  &-btn:disabled &-minusIcon rect {
    fill: $palette--silver;
  }

  &-btn:disabled &-plusIcon rect {
    fill: $palette--silver;
  }
}
