@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.GeocodingInputSearchMobile {
  &-Drawer {
    .Drawer-inner {
      min-height: 95%;
    }

    .Drawer-sliderContainer {
      justify-content: stretch;
    }

    .Drawer-children {
      padding-bottom: 0;
    }

    .GeocodingInputSearch {
      width: 100%;

      &.hide-caret .InputText-input {
        caret-color: transparent;
      }

      .InputText {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $palette--tint-4-grey;
        padding: $spacing--base * 4;
        box-shadow: none;

        &-input {
          font-size: $font-size--c;
          line-height: 0;
        }
      }

      .InputAutocomplete-suggestionsBox {
        position: relative;
        border: none;
        box-shadow: none;
      }
    }

    .InputAutocomplete-suggestionsList {
      max-height: none;
    }
  }
}
