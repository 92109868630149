.RoomFilter {
  padding: $spacing--base * 5 $spacing--base * 4 $spacing--base * 4
    $spacing--base * 4;
}

.ListingFilterModal {
  .RoomFilter {
    padding: $spacing--medium $spacing--base * 4;
    border-bottom: 1px solid $color-superlight;
  }

  .RoomFilter .NumberCounter-label {
    font-size: $font-size--e;
    font-weight: $font-weight--bold;
  }
}
