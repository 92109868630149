.BedsFilter {
  padding: $spacing--base * 3 $spacing--base * 4;
}

.BedsFilter .NumberCounter {
  margin-top: $spacing--base * 4;
}

.BedsFilter .NumberCounter-label {
  color: $color-2019--font-secondary;
}

.BedsFilter--set .NumberCounter-label {
  color: $color-2019--font-primary;
}

.BedsFilter .NumberCounter-icon {
  width: $spacing--medium + $spacing--base;
  height: $spacing--base * 5;
}

.BedsFilter-heading {
  @include typo--body-2;
  color: $color-2019--font-primary;
}

.ListingFilterModal {
  .BedsFilter {
    padding: 0;
  }

  .BedsFilter .NumberCounter {
    margin-top: 0;
  }

  .BedsFilter .NumberCounter:not(:first-of-type) {
    margin-top: $spacing--base * 5;
  }

  .BedsFilter-heading {
    display: none;
  }
}
