$height-filter-bar: 66px;
$height-top-area: $height-app-header + $height-filter-bar;
$height-top-area--mobile: $height-app-header--mobile + $height-filter-bar;
$map-button-height: 40px;

// from 0 to 893 px (smallest)
$height-stickyHeader: 126px;

// from 894 px to 1031 px (medium)
$height-stickyHeader--medium: 156px;

.ListingsPage-outerSmallScreen .root {
  min-height: 0;
}

.ListingsPage .StickyHeader {
  z-index: 110;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.5s;

  &--hidden {
    transform: translateY(-100%);
  }
}

.ListingsPage {
  position: relative;
  min-height: 100vh;

  .ButtonImage {
    margin-right: $spacing--micro;
    width: 18px;
    transform: scaleX(-1);
  }

  .ListButton {
    align-items: center;
    display: flex;
    position: fixed;
    top: $spacing--small;
    left: $spacing--small;
    z-index: 1000;
  }

  .Listings-mapButtonScrollWrapper {
    margin-top: -$map-button-height;
  }

  .MapButton {
    align-items: center;
    display: flex;
    line-height: 40px;
    height: $map-button-height;
    position: sticky;
    top: calc(
      97vh - $map-button-height - 10px
    ); // Fallback for browsers that don't support dvh
    top: calc(100dvh - $map-button-height - 10px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    &.hideMapButton {
      display: none;
    }
  }

  .Listings {
    background: #ffffff;
    display: grid;
    grid-template-columns: 100%;
    .unfix {
      position: absolute;
      bottom: 0;
      top: initial;
      height: calc(100vh - #{$height-top-area});
      z-index: 0;
    }

    &.is-empty {
      min-height: 100vh;

      .Listings-wrapper {
        position: relative;
      }

      .Listings-empty-message {
        @include typo--body-2;
        color: $palette--grey;
        font-weight: normal;
        padding: $spacing--medium 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 45vh;

        &-title {
          @include typo--heading-3;
          color: $palette--dark-grey;
          text-align: center;
          line-height: $spacing--base * 5;
        }

        &-text {
          max-width: 410px;
          text-align: center;
          margin-top: $spacing--small;
        }

        a {
          color: $color-brand;
        }
      }
    }

    &-inner-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0);
      z-index: 50;
      visibility: hidden;
      will-change: background, visibility;
      transition: all 200ms;
      min-height: 100%;

      &-active {
        visibility: initial;
        background: rgba(0, 0, 0, 0.8);
      }
    }

    &-bar {
      position: relative;
      width: 100%;
      min-height: $height-filter-bar;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #e3e9f0;

      &.isMobileMapView {
        display: none;
      }

      .Listings-viewSelector {
        padding-right: 30px;

        @include lt($listingspage-tablet-breakpoint) {
          display: none;
        }

        .Listings-viewListButton,
        .Listings-viewMapButton {
          @include typo--body-3;
          background-color: $palette--pure-white;
          border: 1px solid $palette--tint-3-grey;
          color: $palette--dark-gray;
          cursor: pointer;
          display: inline-flex;
          font-weight: 500;
          line-height: normal;
          padding: 9px;
          width: 70px;

          &--active {
            background-color: $palette--wunder-brand-purple;
            border: 1px solid $palette--wunder-brand-purple;
            color: $palette--pure-white;
          }
        }

        .Listings-viewListButton {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;

          > span {
            margin-left: 6px;
          }
        }
        .Listings-viewMapButton {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          > span {
            margin-left: 3px;
          }
        }
      }
    }

    &-wrapper {
      padding-bottom: $spacing--large;
      position: relative;
      background: #ffffff;

      &--loading {
        position: absolute;
        padding-top: 80px;
        left: 0px;
        bottom: 0;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0.4;
          background: $color-bg no-repeat center;
          background-image: $spinner-white;
        }
      }
    }

    &-errorMessage {
      padding: 15px 35px;

      .ErrorMessage {
        margin-bottom: 0;
      }
    }

    &-map {
      display: block;
      position: sticky;
      top: $height-top-area;
      height: calc(100vh - #{$height-top-area});

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: inset 2px 0 12px 1px rgba(black, 0.1);
        pointer-events: none;
      }

      .leaflet-top {
        top: 0px;
      }
    }

    &.is-mapview {
      .Listings-wrapper {
        width: 0 !important;
      }

      .Listings-map {
        display: block;
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
      }

      @include gt($listingspage-tablet-breakpoint) {
        .Listings-map {
          top: $height-top-area;
          left: 0;
          height: calc(100vh - #{$height-top-area});
        }
      }
    }

    .ImageGallery-navButton {
      display: block;
    }

    .non-touch & .Listing {
      .ImageGallery-navButton {
        visibility: hidden;
        opacity: 0;
        transition: opacity 200ms linear;
      }

      &:hover {
        .ImageGallery-navButton {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    @include gt($listingspage-tablet-breakpoint) {
      display: grid;
      grid-template-columns: 6fr 4fr;

      &-wrapper {
        padding-left: ($spacing--base * 6);
        padding-right: ($spacing--base * 6);
      }

      &-map {
        width: 100%;
      }
    }

    @include lt($listingspage-tablet-breakpoint) {
      &.is-empty .Listings-empty-message {
        padding-left: $spacing--base * 3;
        padding-right: $spacing--base * 3;
      }
    }

    @include lt(480px) {
      &-wrapper {
        &--loading {
          top: $height-app-header--mobile;
        }
      }

      &-errorMessage {
        padding: 10px 0;
        border-radius: 0;
        overflow: hidden;
      }
    }
  }
}

.Listings-wrapper {
  padding-top: $spacing--base * 3;
  margin: 0 ($spacing--base * 3);

  @include gt(large) {
    padding-top: ($spacing--base * 4);
    padding-left: ($spacing--base * 6);
    padding-right: ($spacing--base * 6);
    margin: 0;
  }
}

.Pagination-wrapper {
  margin: 25px 45px;
}

.Pagination-wrapper--mobile {
  margin-top: 20px;
  margin-bottom: 40px;
}

.ListingsPage.hasHeaderWithBanner {
  .ListingsPageSelect__single-value {
    color: $palette--pure-white;
  }

  .ListingsPageSelect__indicators > span + span {
    background: $palette--pure-white;

    span {
      border-color: #2e2e2e transparent transparent !important;
    }
  }

  // Transition between "white" and "image" headers.
  .AppHeader-theme--white {
    .AppHeader-container--border {
      border-bottom: none;
    }

    .ListingsPageSelect__single-value {
      color: rgba($color: $palette--black, $alpha: var(--bgColorOpacity));
    }

    .SearchDrawerTrigger,
    .GeocodingInputSearch--isTransparent .InputText {
      background: rgba($color: #ffffff, $alpha: var(--bgColorOpacity));
      border: 1px solid $palette--tint-3-grey;
    }
    .SearchDrawerTrigger-Text,
    .GeocodingInputSearch--isTransparent .InputText-input {
      color: rgba($color: $palette--black, $alpha: var(--bgColorOpacity));
    }

    .SearchDrawerTrigger-SearchIcon,
    .GeocodingInputSearch--isTransparent .InputAutocomplete-InputText-Icon {
      background: $palette--tint-2-grey;
      border: 1px solid $palette--tint-3-grey;
    }

    .SearchDrawerTrigger-SearchIcon svg path,
    .InputAutocomplete-InputText-Icon svg path {
      fill: #000;
    }

    .WunderflatsLogo-shape {
      opacity: var(--bgColorOpacity);
    }

    .WunderflatsLogoBadge-shape {
      opacity: var(--bgColorOpacity);
    }

    .WunderflatsLogo-lettering {
      fill: rgba($color: $palette--black, $alpha: var(--bgColorOpacity));
    }

    .AppHeader-DesktopLinkList .AppHeader-Link {
      &-text {
        color: rgba($color: $palette--black, $alpha: var(--bgColorOpacity));
      }

      &-badge {
        background: rgba(
          $color: $palette--dark-purple,
          $alpha: var(--bgColorOpacity)
        );
      }

      &:hover {
        background: rgba(
          $color: $palette--tint-1-grey,
          $alpha: var(--bgColorOpacity)
        );
      }
    }

    .AppHeader-SignInLink {
      background: rgba(
        $color: $palette--tint-2-grey,
        $alpha: var(--bgColorOpacity)
      );
      color: rgba($color: $palette--black, $alpha: var(--bgColorOpacity));
    }

    .AppHeaderUserMenu-notifications {
      background: rgba(
        $color: $palette--dark-purple,
        $alpha: var(--bgColorOpacity)
      );
    }

    .AppHeader-LanguageDropdownButton {
      opacity: var(--bgColorOpacity);
    }

    .AppHeaderUserMenu-ToggleButton-desktop {
      opacity: var(--bgColorOpacity);
    }
  }

  .Listings-bar.Listings-bar--withBanner {
    position: sticky;
    // this 2px fixes a Chrome bug where there is some phantom spacing between
    // the header and filter bar.
    top: $height-app-header - 2px;
    padding-top: 2px;
    z-index: 9;
    transition: all 0.3s ease-out;

    &.is-mapview {
      position: absolute;
      top: $height-app-header;
      transition: none;
    }

    @include lt($listingspage-tablet-breakpoint) {
      &.mobile--hidden {
        transform: translateY(calc(-100% - #{$height-app-header}));
      }
    }

    @include lt(992px) {
      top: $height-app-header--mobile;

      &.mobile--hidden {
        transform: translateY(calc(-100% - #{$height-app-header--mobile}));
      }
    }
  }

  .HeaderWithBanner-banner {
    height: 55vh;
    margin-top: -$height-app-header;
    z-index: 0;
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 300px;

    @include gt(medium) {
      min-height: 330px;
    }

    &.RefugeeDistributionModalOpen {
      @include gt(medium) {
        height: 44vh;
      }
    }
    // :before and :after just make the background image darker
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 40%;
      background: linear-gradient(
        0deg,
        rgba(32, 35, 38, 0) 4.17%,
        rgba(32, 35, 38, 0.75) 100%
      );
      z-index: 1;

      @include lt(large) {
        height: 25%;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70%;
      background: linear-gradient(
        180deg,
        rgba(32, 35, 38, 0) 4.17%,
        rgba(32, 35, 38, 0.75) 100%
      );
      z-index: 1;

      @include gt(large) {
        height: 50%;
      }
    }

    .CoverImage-image {
      object-position: center 30%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .HeaderWithBanner-banner-content {
    color: #fff;
    width: 100%;
    z-index: 5;
    margin: 0 0 60px 30px;
    transition: all 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    overflow: hidden;

    @include lt($listingspage-tablet-breakpoint) {
      margin-left: 15px;
    }

    @include lt(large) {
      color: #fff;
      margin-bottom: 30px;
    }

    @include lt(xsmall) {
      display: block;
      text-align: center;
      margin: 0 20px 25px 20px;
    }
  }

  .HeaderWithBanner-banner-content--withLogo {
    @include lt(xsmall) {
      text-align: left;
    }
  }

  .HeaderWithBanner-banner-kicker {
    font-size: $font-size--e;
    line-height: 22.5px;
  }

  .HeaderWithBanner-banner-categoryTitle {
    @include ms-font-size(7);
    line-height: $line-height--jumbo;
    display: inline-block;
    word-wrap: break-word;
    hyphens: auto;
    font-weight: $font-weight--regular;

    @include lt(small) {
      @include ms-font-size(6);
      line-height: $line-height--large;
    }

    &.Caveat {
      font-size: 60px;

      @include lt(small) {
        font-size: 50px;
      }
    }

    &.CinzelDecorative {
      @include lt(small) {
        @include ms-font-size(4);
      }
    }

    &.TimesNewRoman {
      font-weight: bold;

      html[lang="de"] & {
        @include lt(small) {
          @include ms-font-size(4);
          line-height: 35px;
        }
      }
    }

    &.VesperLibre {
      @include lt(small) {
        @include ms-font-size(4);
        line-height: 35px;
      }
    }

    &.Poppins {
      font-size: 46px;
      font-weight: 500;
      line-height: 110%;
      padding-bottom: 15px;
      @include lt(small) {
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .HeaderWithBanner-banner-subText {
    font-weight: normal;
    max-width: 480px;
    font-size: $font-size--f;
    line-height: $line-height--small;
    word-wrap: break-word;
    hyphens: auto;
    text-align: left;

    @include lt(xsmall) {
      max-width: none;
    }
  }

  .ListingsPage-HeaderWithBanner-description--refugees {
    line-height: 135%;
    @include lt(small) {
      font-size: 12px;
      line-height: 135%;
    }
  }

  .ListingsPage-HeaderWithBanner-refugeeDistributionFeaturesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-end;
    min-height: 52px;

    @include gt(small) {
      flex-direction: row;
      justify-content: space-between;
    }

    .HeaderWithBanner-banner-logos {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      margin-left: 6px;
      height: 54px;
      @include gt(small) {
        margin-top: 15px;
      }

      &.withChangeLocationButton {
        @include gt(medium) {
          margin-left: 350px;
        }
      }

      img {
        max-height: 36px;
        @include gt(medium) {
          max-height: 42px;
        }
        &.cofundedByTheEu-Logo {
          align-self: flex-end;
        }
      }
    }

    .ListingsPage-HeaderWithBanner-changeLocationButton {
      margin-top: 15px;
    }

    .ListingsPage-HeaderWithBanner-changeLocationButton
      > .Button--secondaryBlue {
      margin: 6px;
      &:focus {
        box-shadow: none;
        outline: 3px solid $palette--silver;
        outline-offset: 3px;
      }
      @include lt(small) {
        font-size: 12px;
        padding: 0 20px;
      }
    }
  }
  .ListingsPage-HeaderWithBanner-searchLocation {
    margin-top: 15px;
    line-height: 135%;
    @include lt(small) {
      font-size: 12px;
      line-height: 135%;
    }
  }
}
