@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.CustomDropdownSelect-container {
  position: relative;
  width: 100%;
}

.CustomDropdownSelect-input {
  position: relative;
  outline: none;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  background: $palette--tint-1-grey;
  border: 1px solid $palette--tint-4-grey;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 46px;
  padding: 0 20px;
  text-indent: 1px;
  text-overflow: "";
  color: $palette--black;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;

  &--error {
    border-color: $palette--indian-red;
    background: $palette--pale-red;
    color: $palette--indian-red;
    &::placeholder {
      color: $palette--indian-red;
    }
  }

  @include lt(xsmall) {
    &::placeholder {
      font-size: 12px;
    }
  }
}

.CustomDropdownSelect-list {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
  margin-top: 50px;
  max-height: 290px;
  padding: 5px;
  overflow: auto;
  background: $palette--pure-white;
  border-radius: $border-radius--medium;
  border: 1px solid $palette--tint-3-grey;
  box-shadow: $box-shadow-floating-button, $box-shadow-floatingButton;
  z-index: 1002;
  &--active {
    display: block;
  }
}

.CustomDropdownSelect-listItem {
  color: $palette--black;
  background: $palette--pure-white;
  padding: 12px 15px;
  border-radius: $border-radius-base;
  cursor: pointer;
  text-align: left;

  &:nth-child(even) {
    background: $palette--tint-1-grey;
  }
  &:hover {
    background: $palette--tint-3-grey;
  }
}

.CustomDropdownSelect-icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomDropdownSelect-triangle {
  display: inherit;
  margin-top: 2px;
}

.CustomDropdownSelect-errorMessage {
  @include typo--body-2;
  font-weight: $font-weight--regular;
  margin-top: 10px;
}

.CustomDropdownSelect-input:focus {
  -webkit-appearance: none;
  outline: none;
  border: 1px solid $palette--wunder-brand-purple;
  background-color: rgba(84, 46, 188, 0.07);
}

.CustomDropdownSelect-trigger {
  display: flex;
  align-items: center;
}
