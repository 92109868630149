.Listings-map {
  z-index: 1;
}

%listings-map-control-widget {
  padding: $spacing--small;
  font-family: $primary-font-stack;
  background: $palette--pure-white;
  border-radius: $border-radius--medium;
  box-shadow: 0px 7px 24px 0 rgba(169, 179, 188, 0.8);
  font-size: $font-size--f;
  color: $color-text;
}

.ListingsMap-resultsCounter,
.ListingsMap-searchControl {
  padding: $spacing--small;
  font-family: $primary-font-stack;
  background: $palette--pure-white;
  border-radius: $border-radius--medium;
  box-shadow: 0px 7px 24px 0 rgba(169, 179, 188, 0.8);
  font-size: $font-size--f;
  color: $color-text;
}

@include lt(medium) {
  %listings-map-control-widget {
    padding: $spacing--micro;
    font-size: $font-size--g;
  }
}

.ListingsMap-mapUnavailable {
  @include typo--heading-4;
  padding: $spacing--small;
  font-size: $font-size--f;
  text-align: center;
}

.Listings-map--ControlButton {
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  outline: none;
  box-shadow: 0px 7px 24px 0 rgb(169 179 188 / 80%);

  &:hover {
    background-color: #f4f4f4;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0px 7px 24px 0 rgb(169 179 188 / 80%);
  }

  .leaflet-right .Listings-map--ControlButton {
    padding-right: 50%;
  }
}
