@import "../../styles/variables.scss";
@import "../../styles/media";

.RefugeeDistributionModal-Layout {
  padding: 60px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;

  @include lt(small) {
    padding-top: 40px;
  }

  .RefugeeDistributionModal-StepWrapper {
    max-width: 550px;
    margin: auto;
    padding-bottom: 70px;

    @include lt(small) {
      max-width: calc(100% - 40px);
      padding-bottom: 45px;
    }
  }

  .RefugeeDistributionModal-backButton {
    margin: 28px auto 0;

    @include lt(small) {
      margin-top: 25px;
    }
  }
}

.RefugeeDistributionModal-Footer {
  min-height: 70px;
  width: 100%;
}
