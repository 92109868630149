@import "../../styles/variables.scss";

.GeocodingSuggestion-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.GeocodingSuggestion-textAndIconContainer {
  display: flex;
  align-items: center;
}

.GeocodingSuggestion-iconContainer {
  width: $iconSize--large;
  height: $iconSize--large;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing--base * 3;
  background-color: $palette--tint-3-grey;
  border-radius: $border-radius--medium;
}

.GeocodingSuggestion-icon {
  width: 20px;
  height: 20px;
}

.GeocodingSuggestion-textContainer {
  flex: 1;
  padding-right: $spacing--base * 3;
  font-size: $font-size--e;
}

.GeocodingSuggestion-textContext {
  font-size: $font-size--f;
}

.GeocodingSuggestion-arrow {
  display: none;
  position: absolute;
  right: 0;
}

.InputAutocomplete-suggestionItem--focused,
.InputAutocomplete-suggestionItem:hover {
  .GeocodingSuggestion-arrow {
    display: block;
  }
}
